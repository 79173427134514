import { Grid, GridItem, Image, Text, Heading, Box } from '@chakra-ui/react';

const PortfolioPage = () => {
  const categories = [
    {
      logo: 'https://raw.githubusercontent.com/godotengine/godot/master/icon.svg',
      title: 'Game Development',
      description: 'I write clean, efficient code to build fun, responsive games with polished mechanics, smooth performance, and an engaging player experience.',
      bgColor: 'black',
      textColor: 'white'
    },
    {
      logo: 'https://www.vectorlogo.zone/logos/flutterio/flutterio-icon.svg',
      title: 'Mobile Apps',
      description: 'I craft high-quality mobile apps that run smoothly, feel great to use, and are built with clean, reliable code from the ground up.',
      bgColor: '#D3D3D3',
      textColor: 'black'
    },
    {
      logo: 'https://www.vectorlogo.zone/logos/reactjs/reactjs-icon.svg',
      title: 'Web Development',
      description: 'I build fast, reliable web applications with clean code, smooth user interactions, and a strong focus on performance and usability.',
      bgColor: 'black',
      textColor: 'white'
    }
  ];

  return (
    <Box id="third-section"
      height="100dvh"
      overflow="hidden"
      sx={{
        '@media (pointer: coarse)': {
          height: 'calc(100dvh - 1px)' // Fix for mobile Chrome
        }
      }}
    >
      <Grid
        templateRows={{ base: "repeat(3, 1fr)", md: "1fr" }}
        templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
        height="100%"
      >
        {categories.map((category, index) => (
          <GridItem
            key={index}
            bg={category.bgColor}
            p={2}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height={{
              base: "calc((100dvh - env(safe-area-inset-top) / 3)",
              md: "100%"
            }}
            minH="300px"
            position="relative"
          >
            <Box flex="1" display="flex" alignItems="center" justifyContent="center">
              <Image 
                src={category.logo}
                alt={`${category.title} logo`}
                boxSize={{ base: "70px", md: "150px" }}
                mb={2}
                objectFit="contain"
                loading="eager"
                fallbackSrc="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                css={{
                  imageRendering: '-webkit-optimize-contrast',
                  transform: 'translateZ(0)' // Fix for mobile rendering
                }}
              />
            </Box>
            
            <Box flex="1" display="flex" flexDirection="column" >
              <Heading 
                as="h2" 
                fontSize={{ base: "md", md: "xl" }}
                mb={1}
                color={category.textColor}
                fontWeight="bold"
                px={2}
                lineHeight="short"
              >
                {category.title}
              </Heading>
              <Text 
                fontSize={{ base: "xs", md: "md" }}
                color={category.textColor}
                lineHeight="tall"
                px={2}
              >
                {category.description}
              </Text>
            </Box>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

export default PortfolioPage;