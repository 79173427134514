import { Center, Text ,Box,Flex,Icon} from '@chakra-ui/react';
import { IoIosArrowDown } from 'react-icons/io';
import { useState, useEffect} from 'react';


export default function SecondPage() {
const texts = ['API', 'SOFTWARE', 'MOBILE APPS','IOT','WEB APPS','GAMES'];
  
  
// State to keep track of the current text index
const [index, setIndex] = useState(0);
const [isSamsung, setIsSamsung] = useState(false);
const [isSafari, setIsSafari] = useState(false);

// Use effect to update text every 1 second
useEffect(() => {
  const intervalId = setInterval(() => {
    setIndex((prevIndex) => (prevIndex + 1) % texts.length);
  }, 800);

  const userAgent = navigator.userAgent;
    const isSafariBrowser = userAgent.includes('Safari') && !userAgent.includes('Chrome');
    setIsSafari(isSafariBrowser);
    if (userAgent.includes('SamsungBrowser')) {
      setIsSamsung(true);
    }

  // Clear the interval when component unmounts
  return () => clearInterval(intervalId);
}, [texts.length]);
return (
<Box
id="second-section"
  w="100vw"
  h="100vh"
  bg="#D3D3D3"
  display="flex"
  justifyContent="center"
  alignItems="center"
  position="relative"
  p={{ base: "4", md: "6", lg: "8" }}
>
  <Center
    w="100%"
    h="100%"
    padding={{ base: "4", md: "6", lg: "8" }} // Adjusted padding for better fit
  >
    <Box textAlign="center" maxW="90%">
      <Text
        fontSize={{ base: "xl", md: "2xl", lg: "3xl" }} // Slightly smaller font size on base
        color="black"
        mb={{ base: "2", md: "4", lg: "6" }}
        fontFamily="'Verdana', sans-serif"
      >
        What I Deliver
      </Text>
      <Text
        fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }} // Consistent font size
        color="black"
        fontFamily="'Verdana', sans-serif"
      >
        {texts[index]}
      </Text>
    </Box>
  </Center>

  <Box
    position="absolute"
    bottom={{base: isSamsung || isSafari ? '15%' : '9%', md: isSamsung || isSafari ? '6%' : '6%', lg: isSamsung || isSafari ? '6%' : '6%' }} 
    left={{ base: "5%", md: "7%", lg: "10%" }}
    color="white"
  >
    <Flex
      direction="row"
      align="center"
      fontSize={{ base: "sm", md: "md", lg: "lg" }}
    >
      <Text
        color="black"
        fontFamily="'Verdana', sans-serif"
        mr={{ base: "2", md: "4", lg: "6" }}
      >
        CONTINUE
      </Text>
      <Icon
        as={IoIosArrowDown}
        color="black"
        boxSize={{ base: "4", md: "5", lg: "6" }}
      />
    </Flex>
  </Box>
</Box>
      );

}